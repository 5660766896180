import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'

export const Card = styled.div`
  &:hover {
    .card {
      &-image {
        img {
          transform: scale(1.1);
        }
      }
    }
  }

  .card {
    &-image {
      width: 100%;
      height: 125px;
      border-radius: 12px;
      overflow: hidden;
      margin-bottom: 12px;

      @media (min-width: ${breakpoints.md}) {
        height: 172px;
      }

      @media (min-width: ${breakpoints.xl}) {
        height: 200px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform 0.3s;
      }
    }
  }
`
