import React, { useState, useLayoutEffect, useEffect } from 'react'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import useWidth from 'src/hooks/useWidth'

import { Card } from './style'

type ImageProps = {
  url: string
  alt: string
}

type NewsProps = {
  title: string
  image: ImageProps
  slug: string
  categorySlug: string
  subCategorySlug: string
  segmentId: string
  isFromInset?: boolean
  insetCategory?: string
}

type HighlightContentProps = {
  news: NewsProps[]
}

const WIDTH_MD = 768
const WIDTH_LG = 1024

const HighlightContent = ({ news }: HighlightContentProps) => {
  const [isTabletMd, setIsTabletMd] = useState(false)
  const [isTabletLg, setIsTableLg] = useState(false)
  const [data, setData] = useState<NewsProps[]>([])
  const [sendDatalayerEvent] = useDataLayer()

  const width = useWidth(300)

  useLayoutEffect(() => {
    setIsTabletMd(width >= WIDTH_MD)
    setIsTableLg(width < WIDTH_LG)
  }, [width])

  useEffect(() => {
    const newData = news.filter((item) => item.image && item.image.url && item.image.url !== '')
    setData(newData)
  }, [])

  return (
    <div className="row">
      {data.slice(0, isTabletMd && isTabletLg ? 3 : 4).map((item: NewsProps, index: number) => (
        <div className="col-6 col-md-4 col-lg-3" key={`${index + item.slug}`}>
          <Card>
            <a
              href={
                !item.isFromInset
                  ? `/${item.categorySlug}/${item.subCategorySlug}/${item.slug}`
                  : `https://www.inset.com.br/${item.insetCategory}/${item.slug}`
              }
              onClick={() => {
                sendDatalayerEvent({
                  section: item.title,
                  element_action: 'click button',
                  element_name: item.title,
                  redirect_url: `${process.env.BASE_URL}/${item.insetCategory}/${item.slug}`,
                })
              }}
              target={item.isFromInset ? '__blank' : ''}
              rel={item.isFromInset ? 'noreferrer' : ''}
              title={item.title}
            >
              <div className="card-image">
                <img loading="lazy" src={item.image.url} alt={item.image.alt} />
              </div>
              <div className="card-text">
                <h3 className="fs-14 lh-17 fs-md-16 lh-md-20 text-grayscale--500">{item.title}</h3>
                <hr className="line" />
              </div>
            </a>
          </Card>
        </div>
      ))}
    </div>
  )
}

export default HighlightContent
