import React, { useEffect, useState } from 'react'

import { PageProps } from 'gatsby'
import { parseISO, isBefore } from 'date-fns'
import axios from 'axios'
import { NewsField } from 'src/services/articles/articles'
// Components
import Layout from 'src/components/Layout'
import PageTitle from 'src/components/PageTitle'
import ListArticles from 'src/components/ListArticles/ListArticles'
import categoriesJSON from 'src/assets/data/categories.json'
import subcategoriesJSON from 'src/assets/data/subcategories.json'
import HighlightContent from 'src/components/HighlightContent'
import Newsletter from 'src/components/Newsletter'
// Types
import { NoticiaType } from 'src/components/News'
// Styles
import SEO from 'src/components/Seo'
// import { TabItem } from 'src/components/Tabs/DefaultTab/style'
import { News } from 'src/components/News'

export type Article = {
  id: string
  slug: string
  title: string
  introduction: string
  gridImage: gridImage
  categoryName: string
  publishedAt: string
  updatedAt: string
  shares: number
  spotlight: boolean
  readingTime: number
  subCategory: string
  isVideo: boolean
  categoryId: string
  subcategoryId: string
  segmentId: string
}

type gridImage = {
  url: string
  alt: string
}

type subCategoryProps = {
  id: string
  name: string
  slug: string
}

type CategoryProps = {
  pageContext: {
    pageTitle: string
    category: string
    allArticles: Article[]
    categoryid: string
    pageSubTitle: string
    subcategory: subCategoryProps
  }
} & PageProps

const SubCategoryTemplate = ({ pageContext }: CategoryProps) => {
  const allArticlesOrdened = pageContext.articles.sort((a, b) => {
    const aDate = parseISO(a.publishedAt)
    const bDate = parseISO(b.publishedAt)
    return isBefore(aDate, bDate) ? 1 : -1
  })

  const articlesSpotlight = allArticlesOrdened.map((item) => ({
    ...item,
    image: item.gridImage,
  }))

  const [moreShared, setMoreShared] = useState<NoticiaType[]>([])
  const category = categoriesJSON.find((categoryItem) => categoryItem.id === pageContext.categoryId)
  const subCategory = subcategoriesJSON.find((item) => item.id === pageContext.subcategoryId)
  const breadcrumbPath = [
    {
      text: 'Home',
      link: '/',
    },
    {
      text: category?.name,
      link: `/${category?.slug}`,
    },
    {
      text: subCategory?.name,
      link: `/${category?.slug}/${subCategory?.slug}`,
    },
  ]

  const HandleScroll = (id: string) => {
    const section = document.getElementById(id)
    const position = section?.offsetTop || 0
    const poss = position - 187
    window.scroll({
      top: poss,
      behavior: 'smooth',
    })
  }

  const clearLocation = (location: string) => {
    const url = new URL(location)
    const id = url.searchParams.get('id')
    return id
  }

  useEffect(() => {
    const location = clearLocation(window.location.href)

    if (location) {
      setTimeout(() => HandleScroll(location), 100)
    }

    returnArticlesMoreRead()
  }, [])

  async function returnArticlesMoreRead() {
    try {
      const { articles } = pageContext
      const response = await axios.post(`${process.env.CMS_API}/news/analyticsdata`)
      const { data } = response
      const resposta = data.reduce((all, item: AnalyticsData) => {
        const artigo = articles.find((articleFind) => articleFind.id === item.uuid)
        if (!artigo) {
          return all
        }
        return [...all, NewsField(artigo)]
      }, [])
      setMoreShared(resposta)
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <Layout pageContext={{ categoria: category?.slug }}>
      <SEO
        title={`${category?.name} · Inter Invest`}
        url={`${process.env.BASE_URL}/${category?.slug}`}
        meta={[
          {
            name: 'description',
            content: category?.name,
          },
          {
            property: 'og:title',
            content: `${category?.name} · Inter Invest`,
          },
          {
            property: 'og:description',
            content: category?.name,
          },
          {
            property: 'og:locale',
            content: 'pt_BR',
          },
          {
            property: 'og:type',
            content: 'website',
          },
          {
            property: 'og:url',
            content: `${process.env.BASE_URL}/${category?.slug}`,
          },
          {
            property: 'og:site_name',
            content: 'Banco Inter',
          },
          {
            property: 'twitter:site',
            content: '@bancointer',
          },
          {
            property: 'twitter:creator',
            content: '@bancointer',
          },
        ]}
      />
      <section>
        <div className="container">
          <div className="row">
            <PageTitle
              breadcrumb={{ path: breadcrumbPath }}
              pageTitle={pageContext.pageTitle}
              pageSubTitle={pageContext.pageSubTitle}
            />
          </div>
        </div>
      </section>
      <section className="py-5">
        <div className="container">
          <HighlightContent news={articlesSpotlight.slice(0, 4)} />
        </div>
      </section>
      <Newsletter page={category?.name} category={category?.name} subcategory={subCategory?.name} />
      {moreShared.length > 0 && (
        <section className="py-5">
          <News noticias={moreShared} category={pageContext.categoria} title="Mais acessadas" />
        </section>
      )}
      <ListArticles articles={allArticlesOrdened} />
    </Layout>
  )
}

export default SubCategoryTemplate
